.productDetails {
  background: #eeeeee;
  &__main {
    padding: 3rem;
    max-width: 1400px;
    width: 100%;
    margin: auto;

    @media (max-width: 750px) {
      padding: 3rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 2rem 1rem;
    }

    &__heading {
      h4 {
        font-size: 1rem;
        @media (max-width: 600px) {
          font-size: 0.9rem;
        }
      }
      h2 {
        font-size: 3rem;
        color: #556bf6;
        margin-top: 1rem;
        @media (max-width: 600px) {
          font-size: 2rem;
          margin-top: 0.5rem;
        }
      }
    }

    &__content {
      width: 100%;
      min-height: 500px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      background: #fff;
      margin-top: 3rem;
      padding: 5rem 5rem;
      @media (max-width: 1000px) {
        padding: 3rem;
      }
      @media (max-width: 750px) {
        padding: 3rem;
        grid-template-columns: 1fr;
      }
      @media (max-width: 600px) {
        padding: 2rem 1rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 750px) {
          height: 300px;
        }
      }

      &__details {
        &__tag {
          font-size: 1rem;
        }
        &__title {
          margin-top: 1rem;
          font-size: 2rem;
          font-weight: 400;
          @media (max-width: 600px) {
            font-size: 1.3rem;
          }
        }

        &__price {
          font-weight: normal;
          margin: 0;
          font-weight: 600;
          color: #333333;
          display: flex;
          align-items: center;
          width: fit-content;
          font-size: 1.5rem;
          margin-top: 1.5rem;
          @media (max-width: 600px) {
            font-size: 1.1rem;
          }
          &__previous {
            text-decoration: line-through !important;
            margin-left: 0.5rem;
            font-weight: normal;
            color: #b3b3b3;
            display: flex;
            align-items: center;
          }
        }

        &__desc {
          margin-top: 0.5rem;
          font-size: 1rem;
          line-height: 1.8;
          @media (max-width: 600px) {
            font-size: 0.9rem;
            line-height: 1.5;
          }
        }

        &__buy {
          margin-top: 1rem;
          padding: 1rem 2rem;
          background: blue;
          color: #fff;
          font-size: 0.9rem;
          cursor: pointer;
          @media (max-width: 600px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
