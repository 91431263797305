.navBar {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  &__box {
    position: relative;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.06);
    height: 80px;
    @media (max-width: 600px) {
      height: 70px;
    }
  }
  &__logo {
    width: 120px;
    cursor: pointer;
    @media (max-width: 600px) {
      width: 100px;
    }
  }
  &__cart {
    box-sizing: border-box;

    width: fit-content;
    height: 100%;
    display: grid;
    place-content: center;
    cursor: pointer;
    position: relative;

    h3 {
      background-color: #87e68b;
      color: #333;
      font-weight: 600;
      font-size: 0.9rem;
      position: absolute;
      bottom: 35px;
      left: calc(50% + 5px);
      padding: 0.6rem;
      height: 2px;
      width: 2px;
      border-radius: 50%;
      display: grid;
      place-content: center;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  padding: 0 3rem;

  @media (max-width: 900px) {
    padding: 0 2rem;
  }
  @media (max-width: 600px) {
    padding: 0 1rem;
    height: 70px;
  }
  &__heading {
    color: #686868;
    font-weight: 800;
    font-size: 1.7rem;
    @media (max-width: 850px) {
      font-size: 1.5rem;
    }
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }
  &__items {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // border-left: 1px solid #e6e6e6;
    // border-right: 1px solid #e6e6e6;
    @media (max-width: 850px) {
      display: none;
    }
  }
  &__menu {
    display: none;

    @media (max-width: 850px) {
      display: block;
      cursor: pointer;
      margin-right: 2rem;
      margin-top: .5rem;
    }
  }

  &__item {
    padding: 0;
    margin: 0 1rem;
    list-style: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;

    &__active {
      color: #4438cb;
    }
  }
  &__logout {
    display: flex;
    align-items: center;
    @media (max-width: 850px) {
      display: none;
    }
    span {
      margin-left: 0.3rem;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &__mobile {
    background: #fefefe;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    &__close {
      width: fit-content;
      height: 70px;
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 1rem;
      cursor: pointer;

      img {
        width: 24px;
        margin-left: auto;
      }
    }
    &__items {
      padding-bottom: 3rem;
    }
    &__item {
      width: fit-content;
      display: block;
      margin: 0 auto;
      padding: 0;
      margin-top: 2.5rem;
      cursor: pointer;
    }

    &__logout {
      display: flex;
      align-items: center;
      span {
        font-size: 1rem;
        margin-left: 0.1rem;
      }
    }
  }
}
