.solutions {
  background: #eeeeee;
  &__main {
    padding: 3rem;
    max-width: 1400px;
    width: 100%;
    margin: auto;

    @media (max-width: 750px) {
      padding: 3rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 2rem 1rem;
    }

    &__heading {
      h4 {
        font-size: 1rem;
        @media (max-width: 600px) {
          font-size: 0.9rem;
        }
      }
      h2 {
        font-size: 3rem;
        color: #556bf6;
        margin-top: 1rem;
        @media (max-width: 600px) {
          font-size: 2rem;
          margin-top: 0.5rem;
        }
      }
    }

    &__solutions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      margin-top: 3rem;
      @media (max-width: 750px) {
        grid-template-columns: 1fr;
      }

      &__items {
      }

      &__item {
        h4 {
          font-size: 1.5rem;
          font-weight: 500;
          margin-top: 3rem;
          @media (max-width: 750px) {
            font-size: 1.1rem;
          }
        }
        p {
          margin-top: 0.5rem;
          font-size: 0.9rem;
          line-height: 1.8;
        }
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      margin: auto;
      border-radius: 20px;
    }

    // &__visions {
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;
    //   grid-gap: 3rem;
    //   width: 100%;
    //   max-width: 1000px;
    //   display: block;
    //   margin: auto;
    //   margin-top: 3rem;
    // }
    // &__vision {
    // }
  }

  &__weUnderstand {
    // background: #556bf6;
    // background: #ecf3fd;
    margin-top: 3rem;
    p {
      // width: 100%;
      // max-width: 600px;
      font-size: 1.1rem;
      line-height: 1.8;
      margin-top: 2rem;
      font-weight: 500;
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
    h4 {
      margin-top: 2rem;
      font-size: 1.4rem;
      font-weight: 500;
      @media (max-width: 500px) {
        font-size: 1.2rem;
      }
    }
  }
}
