.cartItem {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-gap: 1rem;
  margin: 2rem 1rem;
  @media (max-width: 600px) {
    grid-template-columns: 60px 1fr;
    grid-gap: 0.5rem;
  }
  &__image {
    display: grid;
    place-content: center;
    height: 80px;
    width: 100%;
    background: #f7fafc;
    img {
      width: calc(100% - 20px);
      margin: 0 auto;
      cursor: pointer;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &__cancel {
      cursor: pointer;
    }
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: start;
      h4 {
        margin: 0;
        font-size: 0.95rem;
        @media (max-width: 600px) {
          font-size: 0.85rem;
        }
      }
    }
    &__quantity {
      display: flex;
      &__increase,
      &__decrease,
      &__value {
        width: 30px;
        height: 30px;
        border: 1px solid #e6e6e6;
        display: grid;
        place-content: center;
        @media (max-width: 600px) {
          width: 22px;
          height: 22px;
        }
      }
      &__decrease,
      &__value {
        border-right: 0;
      }
      &__value {
        background: #333;
        color: #fff;
      }
      &__increase,
      &__decrease {
        cursor: pointer;
      }
    }
    &__price {
      display: flex;
      align-items: center;
      @media (max-width: 600px) {
        font-size: 0.9rem;
      }
    }
  }
}
