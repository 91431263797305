.footer {
  background: #fff;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    padding: 3rem 3rem;

    @media (max-width: 900px) {
      padding: 3rem 2rem;
    }
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 600px) {
      padding: 3rem 1rem;
    }
  }
  &__box {
    position: relative;
    background: #fff;
  }
  &__logo {
    width: 120px;
    cursor: pointer;
  }

  &__links {
    @media (max-width: 750px) {
      margin-top: 2rem;
    }
    h3 {
      font-size: 1.1rem;
      font-weight: 600;
    }
    p {
      //   color: #676e7e;
      margin-top: 1rem;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }

  &__desc {
    @media (max-width: 750px) {
      margin-top: 2rem;
    }
    h3 {
      font-size: 1.1rem;
      font-weight: 600;
    }
    p {
      //   color: #676e7e;
      margin-top: 1rem;
      line-height: 1.8;
      font-size: 0.9rem;
    }
  }

  &__copy {
    border-top: 1px solid #676e7e;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 3rem;

    @media (max-width: 900px) {
      padding: 0 2rem;
    }
    @media (max-width: 600px) {
      padding: 0rem 1rem;
    }
    p {
      margin-top: 1rem;
      //   color: #676e7e;
      font-size: 0.85rem;
    }
  }
}
