.home {
  height: auto;
  background: #fff;
  //background: #ecf3fd;

  &__intro {
    height: 450px;
    background: linear-gradient(0deg, #ecf3fdda, #ecf3fdda),
      url("../../images/book-stock-1.jpg");
    //background-image: url("../../images/book-stock-1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background: #ecf3fd;
    display: grid;
    place-content: center;
    // border-bottom: 1px solid #676e7e;

    &__main {
      padding: 1rem;

      h2 {
        text-align: center;
        font-size: 1.5rem;
        color: #556bf6;
        font-weight: 400;
        @media (max-width: 500px) {
          font-size: 1.3rem;
     
        }
      }
      p {
        text-align: center;
        font-size: 2.3rem;
        max-width: 600px;
        // color: #b8b5b5;
        // color: #fff;
        margin-top: 1rem;
        font-weight: 800;
        @media (max-width: 750px) {
          font-size: 1.8rem;
          max-width: 450px;
        }
        @media (max-width: 500px) {
          font-size: 1.6rem;
          max-width: 300px;
          line-height: 1.3;
          //font-weight: 500;
        }
      }
      button {
        display: block;
        margin: auto;
        padding: 1rem 2rem;
        border-radius: 0;
        font-family: inherit;
        font-size: 1rem;
        margin-top: 1rem;
        background: #556bf6;
        color: #fff;
        cursor: pointer;
        @media (max-width: 500px) {
          font-size: 0.9rem;
          padding: 1rem 1.5rem;
        }
      }
    }
  }

  &__cards {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 3rem 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    border-bottom: 1px solid rgba(243, 243, 243, 0.8);
    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
    }
    @media (max-width: 750px) {
      padding: 3rem 2rem;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }
    @media (max-width: 600px) {
      padding: 2rem 1rem;
    }

    &__more {
      border-radius: 1rem;
      padding: 0.7rem 1.5rem;
      background: #eee;
      height: fit-content;
      width: fit-content;
      font-family: inherit;
      font-size: 1rem;
      display: grid;
      font-weight: 500;
      align-self: flex-end;
      cursor: pointer;
      @media (max-width: 750px) {
        margin: auto;
        margin-top: 0;
        font-size: 0.9rem;
        font-weight: 400;
      }
    }

    &__item {
      width: 100%;
      max-width: 400px;
      display: grid;
      grid-template-rows: 300px 1fr;
      margin-top: 1rem;
      background: #eee;
      //background: rgb(218, 218, 218);
      //   background: rgb(235, 235, 235);
      border-radius: 20px;
      cursor: pointer;
      @media (max-width: 750px) {
        margin: auto;
        margin-top: 2rem;
      }
      @media (max-width: 600px) {
        grid-template-rows: 250px 1fr;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
      }

      &__text {
        min-height: 200px;
        padding: 2rem;
        @media (max-width: 600px) {
          padding: 2rem 1rem;
          min-height: 200px;
        }

        h6 {
          font-size: 0.85rem;
          margin-top: 1rem;
        }

        h5 {
          font-weight: normal;
          margin: 0;
          font-weight: 600;
          color: #333333;
          display: flex;
          align-items: center;
          width: fit-content;
          font-size: 1rem;
          margin-top: 1rem;

          span {
            text-decoration: line-through !important;
            margin-left: 0.5rem;
            font-weight: normal;
            color: #b3b3b3;
            display: flex;
            align-items: center;
          }
        }

        h3 {
          font-size: 1.5rem;
          margin-top: 0rem;
          @media (max-width: 600px) {
            font-size: 1.2rem;
          }
        }
        p {
          font-size: 1rem;
          margin-top: 0.3rem;
          @media (max-width: 600px) {
            font-size: 0.9rem;
            margin-top: 0.3rem;
          }
        }
        button {
          padding: 0;
          margin-top: 1rem;
          font-size: 1rem;
          font-family: inherit;
          color: #fdb62f;
          background: transparent;
          cursor: pointer;
          @media (max-width: 600px) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
