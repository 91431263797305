.contactUs {
  background: #eeeeee;
  &__main {
    padding: 4rem;
    max-width: 1400px;
    width: 100%;
    margin: auto;

    @media (max-width: 750px) {
      padding: 3rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 3rem 1rem;
    }

    &__heading {
      h4 {
        font-size: 1rem;
        @media (max-width: 600px) {
          font-size: 0.9rem;
        }
      }
      h2 {
        font-size: 3rem;
        color: #556bf6;
        margin-top: 1rem;
        @media (max-width: 600px) {
          font-size: 2rem;
          margin-top: 0.5rem;
        }
      }
    }

    &__contact {
      margin-top: 5rem;
      h3 {
        font-weight: 500;
        font-size: 2rem;
        @media (max-width: 600px) {
          font-size: 1.3rem;
        }
      }
      p {
        margin-top: 1rem;
        font-size: 1.1rem;
        line-height: 1.8;
        @media (max-width: 600px) {
          font-size: 0.9rem;
        }
      }
    }
  }
}
