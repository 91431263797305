.cart {
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0 1rem;
  animation: show 0.2s ease-in-out 1;
  z-index: 20;
  //   @media (max-width: 600px) {
  //     padding: 0 0.5rem;
  //   }
  @keyframes show {
    from {
      opacity: 0.6;
      transform: translateY(-60px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &__main {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 0.5rem;
    border: 1px solid #e6e6e6;
    background: #fff;
    height: auto;
    box-sizing: border-box;

    &__heading {
      height: 50px;
      box-sizing: border-box;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      h3 {
        font-size: 0.95rem;
      }
      @media (max-width: 600px) {
        h3 {
          font-size: 0.9rem;
        }
      }
    }
    &__footer {
      background: #f7fafc;
      border-top: 1px solid #e6e6e6;

      padding: 0 1rem;
      padding-bottom: .5rem;
      &__form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        margin-top: .5rem;
        @media (max-width: 480px){
          grid-gap: .8rem;
        }
      }

      &__checkout {
        // height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
      }

      button {
        font-family: inherit;
        display: inline-block;
        // margin-left: auto;
        border: 0;
        color: #fff;
        background: #333;
        padding: 0.5rem 1rem;
        width: 100px;
        margin-left: auto;
        display: grid;
        place-content: center;
        cursor: pointer;
        // margin-top: 2rem;
        // margin-bottom: 1rem;

        @media (max-width: 600px) {
          font-size: 0.75rem;
        }
      }
      h4 {
        font-weight: 500;
        display: flex;
        span {
          font-weight: normal;
          display: flex;
          align-items: center;
          margin-left: 0.5rem;
        }
        @media (max-width: 600px) {
          font-size: 0.9rem;
        }
      }
      &__select {
        p {
          font-size: 0.85rem;
          color: inherit;
          font-family: inherit;
          margin: 0;
          margin-bottom: 0.2rem;
          margin-top: 1rem;
        }
      }

      &__input {
        p {
          font-size: 0.85rem;
          color: inherit;
          font-family: inherit;
          margin: 0;
          margin-bottom: 0.2rem;
          margin-top: 1rem;
        }
        input {
          width: 100%;
          height: 45px;
          border: 1px solid #dee5ed;
          outline: none;
          border-radius: 5px;
          padding: 0 1rem;
          // color: #79879a;
          font-size: 0.9rem;
          box-sizing: border-box;
        }

        &__notFound {
          input {
            border: 1px solid tomato;
          }
        }
      }
    }

    &__empty {
      margin: 0 auto;
      width: fit-content;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 2rem 0;
      p {
        color: #b3b3b3;
        text-align: center;
        margin: 0;
        font-size: 0.9rem;
      }
      &__ghost {
        margin: 0 auto;
      }
    }

    &__content {
      height: auto;
      max-height: 25vh;
      overflow-y: auto;
      padding-bottom: 1rem;
    }
    &__cancel {
      cursor: pointer;
    }
  }
}
