.product {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 3rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  &__item {
    display: grid;
    grid-template-rows: 60px 1fr;
    width: 100%;
    height: 300px;
    // background: #f7fafc;
    background: rgb(218, 218, 218);
    &__image {
      display: grid;
      place-content: center;
      margin-bottom: 30px;
      img {
        width: calc(100% - 100px);
        margin: 0 auto;
        cursor: pointer;
        transition: 0.4s;
        &:hover {
          // width: calc(100% - 50px);
          transform: scale(1.1)
        }
        @media (max-width: 600px) {
          width: calc(100% - 70px);
          &:hover {
            // width: calc(100% - 40px);
            transform: scale(1.1)
          }
        }
      }
    }
    &__tags {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
    }
    &__tag {
      background: #333333;
      color: #fff;
      font-size: 0.8rem;
      width: auto;
      height: 30px;
      padding: 0 0.5rem;
      display: grid;
      place-content: center;
      box-sizing: border-box;
    }
    &__cart {
      cursor: pointer;
    }
  }
  &__desc {
    text-align: center;
    margin: 0 auto;
  }
  &__name {
    font-weight: 600;
    margin: 0;
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #b3b3b3;
    color: #333333;
    @media (max-width: 600px) {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
  &__price {
    font-weight: normal;
    margin: 0;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    &__previous {
      text-decoration: line-through;
      margin-left: 0.5rem;
      font-weight: normal;
      color: #b3b3b3;
      display: flex;
      align-items: center;
    }
  }
}
