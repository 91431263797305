.layout {
  &__navBar {
    z-index: 10;
    position: relative;
    // margin-bottom: 1rem;
  }
  &__content {
    z-index: -1;
  }
}
