.shop {
  box-sizing: border-box;
  background: #eeeeee;

  &__main {
    box-sizing: border-box;
    padding: 3rem;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 2rem;

    @media (max-width: 750px) {
      padding: 3rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 3rem 1rem;
    }
    &__heading {
      h4 {
        font-size: 1rem;
        @media (max-width: 600px) {
          font-size: 0.9rem;
        }
      }
      h2 {
        font-size: 3rem;
        color: #556bf6;
        margin-top: 1rem;
        @media (max-width: 600px) {
          font-size: 2rem;
          margin-top: 0.5rem;
        }
      }
    }
    &__content {
      width: 100%;
      margin: auto;
      max-width: 800px;
    }

    &__search {
      display: grid;
      grid-template-columns: 30px 1fr;

      border-radius: 0rem;
      border: 1px solid #d3d3d3;
      background: #f8f8f8;
      height: 50px;
      align-items: center;
      padding-left: 1rem;
      width: 100%;
      margin-top: 3rem;
      // max-width: 500px;
      img {
        width: 27px;
      }
      input {
        width: 100%;
        height: 100%;
        outline: none;
        font-size: 1.1rem;
        padding: 0 1rem;
        background: transparent;
        border: none;
        font-family: inherit;

        &::placeholder {
          color: #acacac;
        }

        @media (max-width: 600px) {
          font-size: 1rem;
        }
      }
    }
    &__products {
      box-sizing: border-box;
      max-width: 1000px;

      // display: flex;
      // justify-content: space-between;
      // flex-wrap: wrap;
      display: grid;
      grid-gap: 0rem 1rem;
      grid-template-columns: 1fr 1fr 1fr;
      margin: auto;
      margin-top: 2rem;
      @media (max-width: 600px) {
        grid-template-columns: 1fr 1fr;
      }
    }

    &__buttons {
      margin-top: 0rem;
      margin-bottom: 2rem;
      display: flex;

      button {
        font-size: 1rem;

        padding: 0.7rem 2rem;
        margin-right: 1.5rem;
        background: rgb(218, 218, 218);
        &:nth-child(2) {
          background: #556bf6;
          color: #fff;
        }
      }
    }
  }
}
