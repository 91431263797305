.aboutUs {
  background: #eeeeee;
  &__main {
    padding: 3rem;
    max-width: 1400px;
    width: 100%;
    margin: auto;

    @media (max-width: 750px) {
      padding: 3rem 2rem;
    }
    @media (max-width: 600px) {
      padding: 2rem 1rem;
    }

    &__heading {
      h4 {
        font-size: 1rem;
        @media (max-width: 600px) {
          font-size: 0.9rem;
        }
      }
      h2 {
        font-size: 3rem;
        color: #556bf6;
        margin-top: 1rem;
        @media (max-width: 600px) {
          font-size: 2rem;
          margin-top: 0.5rem;
        }
      }
    }

    &__intro {
      margin-top: 5rem;
      h3 {
        font-weight: 500;
        text-align: center;
        font-size: 2.5rem;
        @media (max-width: 600px) {
          font-size: 2rem;
        }
        @media (max-width: 500px) {
          font-size: 1.5rem;
          text-align: left;
        }
      }
      p {
        max-width: 700px;
        text-align: center;
        margin: auto;
        margin-top: 1rem;
        line-height: 1.8;
        @media (max-width: 500px) {
          font-size: 0.9rem;
          text-align: left;
        }
      }
    }

    &__image {
      width: 100%;
      max-width: 1000px;
      display: block;
      margin: auto;
      margin-top: 3rem;
      border-radius: 20px;
    }

    &__visions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 3rem;
      width: 100%;
      max-width: 1000px;
      display: block;
      margin: auto;
      margin-top: 3rem;
    }
    &__vision {
      h4 {
        font-size: 1.1rem;
        font-weight: 500;
        margin-top: 3rem;
      }
      p {
        margin-top: 0.5rem;
        font-size: 0.9rem;
        line-height: 1.8;
      }
    }
  }
}
